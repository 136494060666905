import React, { useState } from "react";

function Vacancies({ Notification }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSyllabus, setCurrentSyllabus] = useState("");

  const handleItemClick = (syllabusContent) => {
    setCurrentSyllabus(syllabusContent);
    setIsModalOpen(true);
  };

  return (
    <>
      {/* <Slider {...settings}> */}
      {Notification?.length === 0 ? (
        <p className="text-center pt-[6px] font-sans font-[700] text-[18px] leading-[24px] text-[#000000]">
          Will share the results for the recent exams soon.
        </p>
      ) : (
        Notification?.map((e, i) => {
          return (
            <>
              <div key={i} className="">
                <div className="border overflow-hidden h-full cursor-pointer sm:max-w-[218px] max-w-full w-full rounded-[8px] border-[#D2D0D0] p-[6px]">
                  <div onClick={() => handleItemClick(e?.Syllabus)}>
                    {!e?.Img ? (
                      <img
                        src="assets/about/photo4.png"
                        className="w-full object-cover"
                        alt=""
                      />
                    ) : (
                      <img
                        src={e?.Img}
                        className="w-full rounded-[10px] object-cover"
                        alt=""
                      />
                    )}
                    <div className="pt-[30px]">
                      <div data-aos="fade-left">
                        <p className="text-center sm:h-[80px] h-auto pt-[6px] font-sans font-[700] text-[18px] leading-[24px] text-[#000000]">
                          {e?.Name.length > 40
                            ? `${e?.Name.substring(0, 40)}...`
                            : e?.Name}
                        </p>
                      </div>
                      {/* <div className="border-[#0000004D] border-b sm:max-w-[172px] max-w-full w-full m-auto mt-[17px] mb-[27px]"></div>
                      <div data-aos="fade-left">
                        <p className="flex justify-center items-center font-[Outfit] font-[600] text-[14px] leading-[22px] text-[#D0021B] gap-[10px] pb-[16px]">
                          Know More <img src="assets/about/right.svg" alt="" />
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <div dangerouslySetInnerHTML={{ __html: currentSyllabus }} />
              </Modal>
            </>
          );
        })
      )}
      {/* </Slider> */}
    </>
  );
}

export default Vacancies;

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-3xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Syllabus</h2>
          <button
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={onClose}
          >
            ×
          </button>
        </div>
        <div className="overflow-y-auto max-h-96">{children}</div>
      </div>
    </div>
  );
}
