import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

const data = [
  { 
    id: 1,
    name: "SSC CPO 2024",
    location: "SSC Foundation (S9) Offline Batch (SFO0324)",
    date: "20/03/2024",
    time: "08:00",
  },
  {
    id: 2,
    name: "SSC CHSL 2024",
    location: "Offline Batch",
    date: "08/02/2024",
    time: "08:00",
  },
];

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="assets/home/s-right.svg" alt="" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="assets/home/s-left.svg" alt="" />
    </div>
  );
}

function LatestVacancy({vacancies}) {
  const navigate = useNavigate();


  const settings = {
    className: "center",
    centerMode: true,
    dots: false,
    autoplay: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 6,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1540,
        settings: {
          centerPadding: "100px",
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "40px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: "40px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "60px",
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="slider-container pt-[60px] relative">
        <Slider {...settings}>
          {vacancies?.map((e, i) => {
            return (
              <>
                <div key={i}>
                <Link to={e?.Link} className="no-underline" target="_blank" rel="noopener noreferrer">
                  <div className="border border-[#E3E3E3] mt-[7px] h-[250px] rounded-[6px] pt-[47px] pb-[46px] px-[25px] max-w-[232px] w-full bg-[#FFFFFF] relative">
                    <div
                      style={{ backgroundImage: "url(assets/home/wrap7.svg)" }}
                      className="h-[32.48px] flex justify-center items-center w-[116px] object-cover absolute top-[-7px] right-[4px]"
                    >
                      <p className="font-sans m-0 font-[400] text-[16px] leading-[22px] text-[#FFFFFF]">
                        {e?.mode}
                      </p>
                    </div>
                    <h6 className="font-sans font-[700] text-[20px] leading-[22px] text-[#202020] pb-[23px]">
                     {e?.Discription}
                    </h6>
                    <div className="flex flex-wrap gap-y-[22px] gap-[8px]">
                      {/* <div className="flex items-center gap-[6px]">
                        <img src="assets/home/icon1.svg" alt="" />
                        <p className="font-sans text-[16px] font-[400] leading-[22px] text-[#121212]">
                          {e?.date}
                        </p>
                      </div>
                      <div className="flex items-center gap-[6px]">
                        <img src="assets/home/icon2.svg" alt="" />
                        <p className="font-sans text-[16px] font-[400] leading-[22px] text-[#121212]">
                          {e?.time}
                        </p>
                      </div> */}
                      <div className="flex items-start gap-[6px]">
                        <img src="assets/home/icon3.svg" alt="" />
                        <p className="font-sans m-0 text-[16px] font-[400] leading-[22px] text-[#121212]">
                        {e?.NotificationName}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                </div>
              </>
            );
          })}
        </Slider>
        <div className="flex pt-[44px] sm:pb-[110px] pb-[50px] justify-center items-center">
          <button onClick={() => navigate("/student-zone")} className="font-sans font-[400] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[18px] py-[16px] sm:px-[21px] px-[18px] border-[0.5px] hover:bg-transparent duration-300 ease-in sm:bg-[#D0021B] bg-[#FFFFFF] border-[#D0021B] rounded-[10px] hover:text-[#D0021B] sm:text-[#FFFFFF] text-[#D0021B]">
            Explore Vacancies
          </button>
        </div>
      </div>
    </>
  );
}

export default LatestVacancy;
