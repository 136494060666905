import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";


function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="assets/home/round-right.svg" alt="" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="assets/home/round-left.svg" alt="" />
    </div>
  );
}

function VideoSlider({ getVideolecture }) {
  const settings = {
    className: "center",
    centerMode: true,
    dots: false,
    infinite: true,
    centerPadding: "40px",
    slidesToShow: 3,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <div className="slider-container relative">
        <Slider {...settings}>
          {getVideolecture?.map((e, i) => {
            return (
              <>
                <div className="video-card overflow-hidden" key={i}>
                  <Link
                    to={e?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block relative overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                  >
                    <div className="relative">
                      <img
                        src={e?.thumbnail}
                        className="w-full h-auto object-cover rounded-lg"
                        alt=""
                      />
                      <img
                        src="assets/youtube.png"
                        className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12"
                        alt=""
                      />
                    </div>
                  </Link>

                  <div data-aos="fade-up">
                    <h6 className="font-[Outfit] font-[600] text-[18px] leading-[22px] mt-[18px]">
                      {e?.Discription}
                    </h6>
                  </div>
                </div>
              </>
            );
          })}
        </Slider>
        <div className="flex pt-[36px] justify-center items-center">
          <button className="font-[Outfit] font-[500] text-[16px] leading-[16.43px] py-[16px] px-[66px] border-[0.5px] hover:bg-transparent duration-300 ease-in sm:bg-[#D0021B] bg-[#FFFFFF] border-[#D0021B] rounded-[40px] hover:text-[#D0021B] sm:text-[#FFFFFF] text-[#D0021B]">
            Explore
          </button>
        </div>
      </div>
    </>
  );
}

export default VideoSlider;
