import React from "react";
// import Slider from "react-slick";

// const data = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }];

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="assets/home/round-right.svg" alt="" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="assets/home/round-left.svg" alt="" />
    </div>
  );
}

function ZoneSlider({ AdmitCard }) {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      {Notification?.length === 0 ? (
        <p className="text-center pt-[6px] font-sans font-[700] text-[18px] leading-[24px] text-[#000000]">
          Will share the results for the recent exams soon.
        </p>
      ) : (
        AdmitCard?.map((e, i) => {
          return (
            <>
              <div key={i} className="">
                <div className="border cursor-pointer h-full overflow-hidden sm:max-w-[218px] max-w-full w-full rounded-[8px] border-[#D2D0D0] p-[6px]">
                  <a href={e?.Link} target="_blank" rel="noopener noreferrer">
                  {!e?.Img ? (
                      <img
                        src="assets/about/photo4.png"
                        className="w-full object-cover"
                        alt=""
                      />
                    ) : (
                      <img
                        src={e?.Img}
                        className="w-full rounded-[10px] object-cover"
                        alt=""
                      />
                    )}
                    <div className="pt-[30px]">
                      <div data-aos="fade-left">
                        <p className="text-center pt-[6px] font-sans font-[700] text-[18px] leading-[24px] text-[#000000]">
                          {e?.ExamName}
                        </p>
                      </div>
                      {/* <div className="border-[#0000004D] border-b sm:max-w-[172px] max-w-full w-full m-auto mt-[17px] mb-[27px]"></div>
                      <div data-aos="fade-left">
                        <p className="flex justify-center items-center font-[Outfit] font-[600] text-[14px] leading-[22px] text-[#D0021B] gap-[10px] pb-[16px]">
                          Know More <img src="assets/about/right.svg" alt="" />
                        </p>
                      </div> */}
                    </div>
                  </a>
                </div>
              </div>
            </>
          );
        })
      )}
    </>
  );
}

export default ZoneSlider;
