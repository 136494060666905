import React, { useEffect, useState } from "react";
import Header from "../components/nav/Header";
import Footer from "../components/footer/Footer";
import Aos from "aos";
import { GetApi } from "../ApIcall";

// const data = [
//   { img: "assets/video/photo4.png" },
//   { img: "assets/video/photo3.png" },
//   { img: "assets/video/photo4.png" },
//   { img: "assets/video/photo5.png" },
//   { img: "assets/video/photo4.png" },
//   { img: "assets/video/photo5.png" },
//   { img: "assets/video/photo3.png" },
//   { img: "assets/video/photo4.png" },
//   { img: "assets/video/photo5.png" },
//   { img: "assets/video/photo3.png" },
//   { img: "assets/video/photo4.png" },
//   { img: "assets/video/photo5.png" },
//   { img: "assets/video/photo3.png" },
//   { img: "assets/video/photo4.png" },
//   { img: "assets/video/photo5.png" },
//   { img: "assets/video/photo3.png" },
// ];

function PhotoGallery() {
  // const [year, setYear] = useState("2023");

  const [galleries, setGalleries] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const fetchGalleries = async () => {
      try {
        const response = await GetApi("api/admin/getAllGallary");
        const fetchedGalleries = response?.data?.data || [];
        setGalleries(fetchedGalleries);
        if (fetchedGalleries.length > 0) {
          setSelectedYear(fetchedGalleries[0]?.Year || "");
          setSelectedEvent(fetchedGalleries[0]?.Name || "");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchGalleries();
  }, []);

  const handleYearSelection = (year) => {
    setSelectedYear(year);
    const eventsOfYear = galleries.filter((gallery) => gallery.Year === year);
    if (eventsOfYear.length > 0) {
      setSelectedEvent(eventsOfYear[0].Name);
    } else {
      setSelectedEvent("");
    }
  };

  const handleEventSelection = (event) => {
    setSelectedEvent(event);
  };

  console.log(selectedYear, selectedEvent);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState(null);

  const toggleFullScreen = (image) => {
    setFullScreenImage(image);
    setIsFullScreen(!isFullScreen);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Aos.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <Header />
      <div className="photo-section flex items-center justify-center">
        <div className="container m-auto flex items-center flex-col justify-center">
          <div data-aos="fade-up">
            <h6 className="font-sans text-[#FFFFFF] text-[46px] font-[700] pb-[14px]">
              Photo Gallery
            </h6>
          </div>
          <div data-aos="fade-up">
            <img src="assets/video/line-white.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="gallery-section">
        <div className="container m-auto">
          <div className="flex flex-wrap items-center justify-center pb-[33px] gap-[15px]">
            {galleries
              .reduce((uniqueYears, gallery) => {
                if (!uniqueYears.includes(gallery.Year)) {
                  uniqueYears.push(gallery.Year);
                }
                return uniqueYears;
              }, [])
              .map((year) => (
                <div data-aos="fade-down">
                  <button
                    key={year}
                    onClick={() => handleYearSelection(year)}
                    className={`py-[12px] px-[49px] rounded-[30px] font-[Outfit] font-[400] text-[18px] leading-[28px] text-center ${
                      selectedYear === year
                        ? "bg-[#D0021B] border border-[#D0021B] text-[#FFFFFF]"
                        : "bg-[#FFFFFF] border border-[#D8D2D2] text-[#616161]"
                    }`}
                  >
                    {year}
                  </button>
                </div>
              ))}
          </div>
          {selectedYear && (
            <div className="flex flex-wrap gap-[14px] items-center justify-center mb-[60px]">
              {galleries
                .filter((gallery) => gallery.Year === selectedYear)
                .map((gallery) => (
                  <button
                    key={gallery._id}
                    onClick={() => handleEventSelection(gallery.Name)}
                    className={`py-[12px] px-[49px] rounded-[30px] font-[Outfit] font-[400] text-[18px] leading-[28px] text-center ${
                      selectedEvent === gallery?.Name
                        ? "bg-[#D0021B] border border-[#D0021B] text-[#FFFFFF]"
                        : "bg-[#FFFFFF] border border-[#D8D2D2] text-[#616161]"
                    }`}
                  >
                    {gallery.Name}
                  </button>
                ))}
            </div>
          )}
          <div className="grid sm:grid-cols-4 grid-cols-1 sm:justify-items-start justify-items-center gap-[64px] sm:gap-y-[98px] gap-y-[20px]">
            {galleries
              .find((g) => g.Year === selectedYear && g.Name === selectedEvent)
              ?.Images.map((image, index) => (
                <div
                  className="photo cursor-pointer"
                  style={isFullScreen ? fullScreenContainerStyle : {}}
                >
                  <img
                    src={image}
                    key={index}
                    alt={`photoimage${index}`}
                    className={isFullScreen ? "full-screen" : ""}
                    onClick={() => toggleFullScreen(image)}
                  />
                </div>
              ))}
            {/* {data?.map((e, i) => {
              return (
                <>
                  <div
                    key={i}
                    className="w-[226.48px] h-[212.58px] rounded-[13.08px] border-[0.82px] border-[#000000] relative"
                  >
                    <div data-aos="fade-down">
                      <img
                        src={e?.img}
                        className="absolute !w-[249.37px] h-[248.55px] top-[11.45px] rounded-[13.08px] border-[0.82px] border-[#000000] left-[11.45px]"
                        alt=""
                      />
                    </div>
                  </div>
                </>
              );
            })} */}
          </div>
        </div>
        {isFullScreen && (
          <div
            className="full-screen-image-container"
            style={fullScreenContainerStyle}
          >
            <button
              className="close-button"
              style={closeButtonStyle}
              onClick={handleCloseFullScreen}
            >
              Close
            </button>
            <img
              src={fullScreenImage}
              alt="Full Screen"
              className="full-screen-image"
            />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

const fullScreenContainerStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 9999,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const closeButtonStyle = {
  position: "absolute",
  top: 10,
  right: 10,
  padding: "8px 12px",
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  border: "none",
  borderRadius: 4,
  cursor: "pointer",
  fontSize: 16,
  color: "#333",
};

export default PhotoGallery;
