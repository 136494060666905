import React, { useEffect } from "react";
import Header from "../components/nav/Header";
import Footer from "../components/footer/Footer";
import ExamButtons1 from "../components/buttons/ExamButtons1";
import Aos from "aos";
import { Link } from "react-router-dom";

function Exam2() {
  function handleDownload() {
    const pdfUrl = "/assets/pdf/IBPSRRBsPOClerkSyllabus.docx";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", "IBPS RRBs PO Clerk Syllabus.docx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    Aos.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <Header />
      <div className="mt-[82px] overflow-hidden relative">
        <img
          src="assets/video/photo1.png"
          className="w-full sm:h-auto h-[150px] object-cover"
          alt=""
        />
        <div className="container m-auto">
          <div className="absolute flex items-center flex-col top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
            <div data-aos="fade-up">
              <p className="font-sans font-[700] text-[24px] leading-[24px] text-center text-[#000000]">
                Exam
              </p>
              <h6 className="font-sans font-[700] pb-[10px] sm:text-[36px] text-[24px] sm:leading-[36.97px] leading-[30px] text-center text-[#D0021B]">
                IBPS RRB's PO & Clerk
              </h6>
              <img src="assets/video/line.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#fffcf9] sm:h-[340px] relative">
        <div className="container m-auto">
          <div className="max-w-[1160px] sm:absolute relative sm:h-[232px] w-full left-[50%] translate-x-[-50%] sm:top-[-72px] top-0">
            <ExamButtons1 />
            <div className="rounded-[30px] sm:pl-[54px] pl-[20px] sm:pr-[43px] pr-[20px] pt-[35px] pb-[35px] w-full sm:absolute relative sm:top-[90px] top-0 bg-[#D0021B]">
              <div className="flex justify-between sm:flex-row flex-col items-center gap-[32px]">
                <div>
                  <h6 className="font-sans font-[700] text-[32px] leading-[43.65px] text-[#FFFFFF] pb-[20px]">
                    IBPS RRB's PO & Clerk
                  </h6>
                  <p className="font-[Outfit] font-[600] text-[16px] leading-[22px] text-[#FFFFFF]">
                    Age Limit – 20 – 28 Years <br />
                    Qualification – Any Graduate <br />
                    Pattern of Exam- Objective (Negative Marking 0.25)
                  </p>
                  <div className="bg-[#FFFFFF] h-[6px] rounded-[48px] mt-[24px] mb-[45px] w-full"></div>
                </div>
                <div className="w-[50%]">
                  <img src="assets/img1.png" className="pt-[18px]" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative overflow-y-hidden bg-[#fffcf9] sm:px-[100px] px-[20px] overflow-x-auto">
        <div className="container m-auto">
          <div data-aos="fade-up">
            <h6 className="bg-[#1C2672] text-[#FFFFFF] px-[22px] py-[10px] border-[0.81px] border-[#000000] rounded-t-[24px] font-sans font-[700] text-[20px] leading-[35px]">
              PRELIMS
            </h6>
          </div>
          <div data-aos="fade-up">
            <table className="w-full text-sm text-left rtl:text-right">
              <thead className="text-xs bg-[#F1F2F6] mb-[12px] text-[#000000]">
                <tr>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] p-[19px]">
                    Section
                  </th>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                    Questions
                  </th>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                    Marks
                  </th>
                  <th className="font-sans font-[600] text-[16px] leading-[24px] text-[#000000] py-[19px]">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody className="pt-[10px]">
                <tr className="bg-white border rounded-[4px] border-[#D2D0D0]">
                  <td className="py-[20px] pl-[22px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                    Quantitative Aptitude
                  </td>
                  <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                    40
                  </td>
                  <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                    40
                  </td>
                  <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                    45 Minutes
                  </td>
                </tr>
                <tr className="bg-white border rounded-[4px] border-[#D2D0D0]">
                  <td className="py-[20px] pl-[22px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                    Reasoning Ability
                  </td>
                  <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                    40
                  </td>
                  <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                    40
                  </td>
                  <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px]">
                    45 Minutes
                  </td>
                </tr>
              </tbody>
              <tr className="bg-[#D0021B] border rounded-[4px] border-[#D9D9D9]">
                <th className="pl-[22px] py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]">
                  Total
                </th>
                <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]">
                  80
                </td>
                <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]">
                  80
                </td>
                <td className="py-[20px] font-[Outfit] font-[400] text-[18px] leading-[22px] text-[#FFFFFF]">
                  &nbsp;
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div className="container overflow-hidden m-auto sm:mt-[80px] mt-[20px]">
        <div data-aos="fade-right">
          <h6 className="bg-[#D0021B] exam-btn mb-[16px] max-w-[100px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
            Mains
          </h6>
        </div>

        <div className="flex flex-col gap-[12px]">
          <div data-aos="fade-up">
            <div className="grid grid-cols-12 bg-[#F1F2F6] p-[19px]">
              <div className="sm:col-span-4 col-span-4">
                <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                  Subject
                </p>
              </div>
              <div className="col-span-3">
                <p className="font-sans sm:pl-[40px] pl-[10px] font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                  Questions
                </p>
              </div>
              <div className="col-span-3">
                <p className="font-sans sm:pl-[40px] pl-[10px] font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                  Marks
                </p>
              </div>
              <div className="col-span-2">
                <p className="font-sans sm:pl-[40px] pl-[10px] font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                  Time
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-up">
            <div className="flex flex-col gap-[12px]">
              <div>
                <div className="grid grid-cols-12 items-center border-b bg-[#ffffff] border border-[#D2D0D0]">
                  <div className="col-span-4 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      General/Financial Awareness
                    </p>
                  </div>
                  <div className="col-span-3 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      40
                    </p>
                  </div>
                  <div className="col-span-3 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      40
                    </p>
                  </div>
                  <div className="col-span-2 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      2 Hours
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12 items-center border-b bg-[#ffffff] border border-[#D2D0D0]">
                  <div className="col-span-4 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      General English/Hindi
                    </p>
                  </div>
                  <div className="col-span-3 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      40
                    </p>
                  </div>
                  <div className="col-span-3 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      40
                    </p>
                  </div>
                  <div className="col-span-2 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      2 Hours
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12 items-center border-b bg-[#ffffff] border border-[#D2D0D0]">
                  <div className="col-span-4 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      Quantitative Aptitude
                    </p>
                  </div>
                  <div className="col-span-3 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      40
                    </p>
                  </div>
                  <div className="col-span-3 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      40
                    </p>
                  </div>
                  <div className="col-span-2 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      2 Hours
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12 items-center border-b bg-[#ffffff] border border-[#D2D0D0]">
                  <div className="col-span-4 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      Reasoning Ability
                    </p>
                  </div>
                  <div className="col-span-3 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      40
                    </p>
                  </div>
                  <div className="col-span-3 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      40
                    </p>
                  </div>
                  <div className="col-span-2 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      2 Hours
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12 items-center border-b bg-[#ffffff] border border-[#D2D0D0]">
                  <div className="col-span-4 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      Computer
                    </p>
                  </div>
                  <div className="col-span-3 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      40
                    </p>
                  </div>
                  <div className="col-span-3 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      40
                    </p>
                  </div>
                  <div className="col-span-2 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      2 Hours
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12 items-center border-b bg-[#ffffff] border border-[#D2D0D0]">
                  <div className="col-span-4 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      Total
                    </p>
                  </div>
                  <div className="col-span-3 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      200
                    </p>
                  </div>
                  <div className="col-span-3 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      200
                    </p>
                  </div>
                  <div className="col-span-2 border-r py-[14px] pl-[14px] border-[#D2D0D0]">
                    <p className="font-sans font-[600] sm:text-[18px] text-[14px] sm:leading-[24px] leading-[20px]">
                      2 Hours
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div data-aos="fade-right">
            <h6 className="bg-[#D0021B] sm:mt-[80px] mt-[20px] exam-btn mb-[16px] max-w-[250px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
              IBPS RRB Syllabus
            </h6>
          </div>
          <div data-aos="fade-right">
            <h6 className="bg-[#D0021B] mb-[16px] max-w-[150px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
              Reasoning
            </h6>
          </div>
          <div data-aos="fade-up">
            <div className="grid sm:grid-cols-12 grid-cols-1 sm:mb-[80px] mb-[30px] sm:gap-0 gap-[20px] bg-[#FFFFFF] rounded-[4px] border border-[#D2D0D0] py-[30px] px-[28px]">
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Puzzles - Seating Arrangement: Circular/Direction-based/MISC
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Number Series
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Odd man out
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Coding-Decoding
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Blood Relation
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Analogy
                </p>
              </div>
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Syllogism
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Alphabet Test
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Ranking and Time
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Causes and Effects
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Direction Sense
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Figure Series
                </p>
              </div>
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Word Formation
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Statement and Assumption
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Assertion and Reason
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Statement and Conclusion
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Statement and Arguments
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Statements and Action Courses
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-right">
            <h6 className="bg-[#D0021B] mb-[16px] max-w-[250px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
              Quantitative Aptitude:-
            </h6>
          </div>
          <div data-aos="fade-up">
            <div className="grid sm:grid-cols-12 grid-cols-1 sm:mb-[80px] mb-[30px] sm:gap-0 gap-[20px] bg-[#FFFFFF] rounded-[4px] border border-[#D2D0D0] py-[30px] px-[28px]">
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Number System
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Data Interpretation - Bar Graph, Line Graph & Pie chart
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  HCF & LCM
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Profit & Loss
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Simple Interest & Compound Interest
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Time & Work
                </p>
              </div>
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Time & Distance
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Decimal & Fraction
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Averages
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Mensuration
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Simplification
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Partnership
                </p>
              </div>
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Percentages
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Ratio & Proportion
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Averages
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Case Studies Charts and Graphs
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Permutation & Combination
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Probability
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-right">
            <h6 className="bg-[#D0021B] mb-[16px] max-w-[250px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
              General Awareness
            </h6>
          </div>
          <div data-aos="fade-up">
            <div className="grid sm:grid-cols-12 grid-cols-1 sm:mb-[80px] mb-[20px] sm:gap-0 gap-[20px] bg-[#FFFFFF] rounded-[4px] border border-[#D2D0D0] py-[30px] px-[28px]">
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Current Affairs National
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  International Current Affairs
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Sports Abbreviations
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Currencies & Capitals
                </p>
              </div>
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  General Science
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Government Schemes & Policies
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Banking Awareness
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Awards and Honors
                </p>
              </div>
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  RBI
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Books and Authors
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  National Parks & Sanctuaries
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-right">
            <h6 className="bg-[#D0021B] mb-[16px] max-w-[500px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
              English Language or Hindi Language Syllabus:-
            </h6>
          </div>
          <div data-aos="fade-up">
            <div className="grid sm:grid-cols-12 grid-cols-1 sm:mb-[80px] mb-[30px] sm:gap-0 gap-[20px] bg-[#FFFFFF] rounded-[4px] border border-[#D2D0D0] py-[30px] px-[28px]">
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Reading Comprehensions
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Grammar / Vyakaran
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Spotting Errors
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Fill in the Blanks
                </p>
              </div>
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Misspelled Words
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Jumbled Words
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Rearrangement of Sentence
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Jumbled up sentences
                </p>
              </div>
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Idioms and Phrases
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Cloze Tests
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  One word Substitution
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Antonyms and Synonyms
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-right">
            <h6 className="bg-[#D0021B] mb-[16px] max-w-[300px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
              Computer Knowledge:-
            </h6>
          </div>
          <div data-aos="fade-up">
            <div className="grid sm:grid-cols-12 grid-cols-1 sm:mb-[80px] mb-[30px] sm:gap-0 gap-[20px] bg-[#FFFFFF] rounded-[4px] border border-[#D2D0D0] py-[30px] px-[28px]">
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Fundamentals of Computer
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  History of Computers
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Future of Computers
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Basic Knowledge of Internet
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Networking Software & Hardware
                </p>
              </div>
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Computer Shortcut Keys
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  MS Office
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Database
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Security Tools
                </p>
              </div>
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Virus
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Hacking
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Trojans Input and Output Devices
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Computer Languages
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-right">
            <h6 className="bg-[#D0021B] mb-[16px] max-w-[300px] w-full font-sans font-[600] text-[20px] leading-[32px] text-[#FFFFFF] rounded-[4px] py-[6px] px-[16px]">
              Financial Awareness:-
            </h6>
          </div>
          <div data-aos="fade-up">
            <div className="grid sm:grid-cols-12 grid-cols-1 mb-[80px] sm:gap-0 gap-[20px] bg-[#FFFFFF] rounded-[4px] border border-[#D2D0D0] py-[30px] px-[28px]">
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Current events in Financial World
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Monetary Policy
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Budget
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Economic Survey
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Banking Banking Reforms in India
                </p>
              </div>
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Bank Accounts of Special Individuals Loans
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Asset Reconstruction Companies
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Non Performing Assets
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Restructuring of Loans
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Bad Loans
                </p>
              </div>
              <div className="col-span-4 flex flex-col gap-[20px]">
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Risk Management
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  BASEL I
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  BASEL II
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  BASEL III
                </p>
                <p className="font-[Outfit] font-[400] text-[16px] leading-[22px] text-[#000000]">
                  Organisations - RBI, SEBI, IMF, World Bank & Others
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container m-auto">
        <div className="sm:py-[72px] py-[30px] flex sm:flex-row flex-col items-center justify-center gap-[16px]">
          <Link
            to="https://live.winnersinstitute.in/courses?categoryId="
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-[22px] bg-[#D0021B] py-[4px] pr-[7px] pl-[20px] rounded-full font-[Outfit] font-[500] text-[16px] leading-[16.43px] text-[#FFFFFF]"
          >
            Purchase Courses <img src="assets/home/round-right.svg" alt="" />
          </Link>
          <button
            onClick={handleDownload}
            className="flex items-center gap-[22px] border border-[#D0021B] py-[4px] pr-[7px] pl-[20px] rounded-full font-[Outfit] font-[500] text-[16px] leading-[16.43px] text-[#D0021B]"
          >
            Download Details <img src="assets/video/download.svg" alt="" />
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Exam2;
